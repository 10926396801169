<template>
  <div>
    <a-page-header
      title="返回"
      :sub-title="this.$route.query.id ? '修改回放信息' : '新增回放'"
      @back="() => $router.go(-1)"
    />
    <a-row>
      <a-col :span="12">
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>
          <a-form-item label="简介">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="addFromData.desc"
            />
          </a-form-item>
          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="7">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="封面图">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>

          <a-form-item label="视频上传">
            <a-upload
              accept=".rm,.mp4,.m4v,.rmvb,.dvix,.3gp,.mov,.dat ,.mkv,.flv"
              action="https://up-cn-east-2.qiniup.com"
              :before-upload="beforeUploadVideo"
              :data="videoForm"
              :headers="headers"
              @change="handleVideoChange"
              :fileList="fileList"
            >
              <a-button
                type="primary"
                :disabled="fileList.length > 0"
                :loading="videoLoading"
              >
                <a-icon type="upload" /> 视频上传
              </a-button>
            </a-upload>
          </a-form-item>

          <!-- <a-form-item label="视频截图">
            <a-upload
              name="file"
              @preview="handlePreview"
              :action="uploadImage"
              list-type="picture-card"
              :before-upload="beforeUpload"
              @change="handleChange2"
              :fileList="imgFileList"
             
            >
              <div v-if="imgFileList.length < 6">
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel2"
            >
              <img
                alt="example"
                style="max-width:450px;max-height:450px"
                :src="previewImage"
              />
            </a-modal>
          </a-form-item> -->
        </a-form>
      </a-col>
      <a-col :span="4">
        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="选择栏目">
            <GroupSelect
              @GroupSelect="GroupSelect"
              :selectId="addFromData.group_id"
              style="width:200px"
            ></GroupSelect>
          </a-form-item>

          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>
          <a-form-item label="排序">
            <a-input-number
              :min="0"
              v-model="addFromData.list_order"
              style="width:160px"
            />
          </a-form-item>
          <a-form-item label="会员手机号">
            <a-input-number
              :min="0"
              v-model="addFromData.mobile"
              style="width:160px"
            />
          </a-form-item>
          <!-- <a-form-item label="是否确定直播时间">
            <a-radio-group
              v-model="addFromData.is_sure"
              @change="is_sureChange"
            >
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item> -->
          <a-form-item label="创建时间">
            <a-date-picker
              showTime
              v-model="addFromData.create_time"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :locale="locale"
            />
          </a-form-item>

          <a-form-item label="开始时间">
            <a-date-picker
              showTime
              v-model="addFromData.start_time"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :locale="locale"
            />
          </a-form-item>
          <a-form-item label="结束时间">
            <a-date-picker
              showTime
              v-model="addFromData.end_time"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :locale="locale"
            />
          </a-form-item>

          <a-form-item>
            <a-button
              type="primary"
              style="margin-left:100px"
              @click="handleOk"
            >
              保存
            </a-button></a-form-item
          >
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  uploadImage,
  getQiniuToken,
  directOneSaveFile,
  livePlaySave,
  liveDetail,
  getVideoTime,
} from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import UE from "@/components/UE/UE.vue";
export default {
  data() {
    return {
      locale,
      uploadImage,
      addFromData: {
        id: "",
        mobile: "",
        company_id: undefined,
        group_id: undefined,
        title: "",
        desc: "",
        content: "",
        video_url: "",
        company_name: undefined,
        list_order: 50,
        img_id: "",
        start_time: "",
        end_time: "",
        create_time: "",
        // 3.2新增
        // images: "",
        video_time: "",
      },
      fileList: [],
      videoLoading: false,
      imageUrl: "",
      loading: false,
      headers: {
        token: "",
      },
      videoForm: {
        token: "",
        key: "",
      },
      params: {},

      //视频截图
      // imgFileList: [],
      // previewVisible: false,
      // previewImage: "",
      // imageList: [],
    };
  },
  components: {
    UE,
  },
  computed: {},
  watch: {
    imageList() {
      let arr = [];
      if (this.imageList) {
        this.imageList.forEach((item) => {
          arr.push(item.uid);
        });
      }
      this.addFromData.images = arr.toString();
    },
  },
  created() {
    getQiniuToken().then((res) => {
      if (res.code == 1) {
        this.headers.token = res.data.uptoken;
        this.videoForm.token = res.data.uptoken;
      }
    });
    if (this.$route.query.id) {
      liveDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData = { ...res.data };
          // if (res.data.extend_info.images_list) {
          //   res.data.extend_info.images_list.forEach((item) => {
          //     this.imgFileList.push({
          //       uid: item.id,
          //       name: "image.png",
          //       url: item.url,
          //       status: "done",
          //     });
          //     this.imageList.push({
          //       uid: item.id,
          //       name: "image.png",
          //       url: item.url,
          //       status: "done",
          //     });
          //   });
          // }

          if (res.data.company) {
            this.addFromData.company_name = res.data.company.company_name;
          } else {
            this.addFromData.company_id = undefined;
            this.addFromData.company_name = undefined;
          }
          if (res.data.extend_info.play_type == 1) {
            this.fileList = [
              {
                uid: res.data.extend_info.video_url,
                name: "视频已上传，如需更换请重新上传",
              },
            ];
          }

          this.imageUrl = res.data.img_url;

          this.addFromData.mobile = res.data.users.mobile;
          this.addFromData.video_url = res.data.extend_info.video_url;
          this.addFromData.start_time =
            res.data.start_time == 0
              ? ""
              : moment(Number(res.data.start_time + "000")).format(
                  "YYYY/MM/DD HH:mm:ss"
                );
          this.addFromData.end_time =
            res.data.end_time == 0
              ? ""
              : moment(Number(res.data.end_time + "000")).format(
                  "YYYY/MM/DD HH:mm:ss"
                );
          this.addFromData.create_time =
            res.data.create_time == 0
              ? ""
              : moment(Number(res.data.create_time + "000")).format(
                  "YYYY/MM/DD HH:mm:ss"
                );
        }
      });
    }
  },
  methods: {
    // 视频截图
    // handleChange2(info) {
    //   let { fileList } = info;
    //   this.imgFileList = [...fileList];
    //   if (info.file.status === "done") {
    //     this.imageList.push({
    //       uid: info.file.response.data.id,
    //       name: "image.png",
    //       url: info.file.response.data.url,
    //       status: "done",
    //     });
    //   }
    //   if (info.file.status === "removed") {
    //     if (typeof info.file.uid == "string") {
    //       this.imageList.filter((item, index) => {
    //         if (item.uid == info.file.response.data.id) {
    //           this.imageList.splice(index, 1);
    //         }
    //       });
    //     } else {
    //       this.imageList.filter((item, index) => {
    //         if (item.uid == info.file.uid) {
    //           this.imageList.splice(index, 1);
    //         }
    //       });
    //     }
    //   }
    // },
    // handleCancel2() {
    //   this.previewVisible = false;
    // },
    // handlePreview(file) {
    //   this.previewImage = file.url || file.response.data.url;
    //   this.previewVisible = true;
    // },

    // -----------分割--------------
    // is_sureChange(e) {
    //   if (e.target.value == 0) {
    //     this.addFromData.start_time = "";
    //     this.addFromData.end_time = "";
    //   }
    // },
    getUeContent(val) {
      this.addFromData.content = val;
    },
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    GroupSelect(val) {
      this.addFromData.group_id = val;
    },
    handleOk() {
      livePlaySave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存成功");
          this.$router.go(-1);
        }
      });
    },
    handleVideoChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "uploading") {
        this.videoLoading = true;
      }
      if (info.file.status === "done") {
        this.params = {
          file_hash: info.file.response.hash,
          file_key: info.file.response.key,
          origin_name: info.file.name,
        };
        this.videoLoading = false;
        directOneSaveFile(this.params).then((res) => {
          if (res.code == 1) {
            this.addFromData.video_url = res.data.id;
            getVideoTime({ id: res.data.id }).then((res) => {
              if (res.code == 1) {
                this.addFromData.video_time = res.data.video_time;
              }
            });
          }
        });
      }
      if (info.file.status === "removed") {
        this.addFromData.video_url = "";
      }
    },
    beforeUploadVideo(file) {
      this.addFromData.video_time = "";
      var file_ext = file.name.substr(file.name.lastIndexOf("."));
      var file_dir = "files/" + moment(new Date()).format("YYYYMMDD") + "/";
      var file_name =
        parseInt(new Date().valueOf() / 1000) +
        "-" +
        parseInt(Math.random() * 8999 + 1000) +
        file_ext;
      this.videoForm.key = file_dir + file_name;
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 500MB!");
        return false;
      }
    },
    // 封面图
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
